import React from 'react'
import about from "../assets/images/about/about.jpg"
import mission from "../assets/images/about/mission.jpg"
import vision from "../assets/images/about/vision.jpg"
import homeschooling from "../assets/images/about/homeschooling.png";
import assessment from "../assets/images/about/assessment.png";
import community from "../assets/images/about/community.png"
import "../assets/style/about.css"
import { Helmet } from 'react-helmet';


const About = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About US | Sah Education</title>
                <link rel="canonical" href="https://saheducation.co.uk/about" />
            </Helmet>
            <div className="about_us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="about_desc">
                                <h2>About Us</h2>
                                <p>At SAH Education Consultant and Recruiting Ltd, we are dedicated to guiding students and professionals towards achieving their academic and career aspirations. With personalized advice and comprehensive support, we help you navigate educational pathways, career opportunities, and international placements. Our expert team connects top talent with leading organizations and partners with esteemed educational institutions to offer the best opportunities for growth. Trust SAH Education Consultant and Recruiting Ltd to be your partner in unlocking your potential and achieving your goals.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <img src={about} alt="" style={{ width: "100%" }} />
                        </div>
                    </div>

                </div>
            </div>


            <div className="mission_section pb-80">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-sm-2">
                            <img src={mission} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-6 col-sm-2">
                            <h3>Our Mision</h3>
                            <p> To empower students with personalized educational guidance, fostering informed decisions and smooth transitions into higher education. We strive to support each student's unique journey toward academic and professional success.

                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mission_section pb-80">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-sm-2">
                            <h3>Our Vision</h3>
                            <p> To be a globally recognized leader in educational consultancy, inspiring lifelong learning and academic excellence. We aim to create a world where every student has access to the best educational opportunities.
                            </p>
                        </div>
                        <div className="col-lg-6 col-sm-2">
                            <img src={vision} alt="" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="mission_section pb-80">
                <div className="container">
                    <h3>Our Keys</h3>
                    <div className="row align-items-center justify-content-center">

                        <div className="col-lg-4 col-sm-12">
                            <div className="keys_card">
                                <img src={homeschooling} alt="" />
                                <h4 className="text-center">Interactive Self-Assessment <br /> Tools</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="keys_card">
                                <img src={assessment} alt="" />
                                <h4 className="text-center">Comprehensive Homeschooling Resources</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="keys_card">
                                <img src={community} alt="" />
                                <h4 className="text-center">Community and Support <br /> Forum</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About