import React, { useState } from 'react'
import "../assets/style/studyabroaddetail.css"
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import useEnquiry from '../useHooks/useEnquiry';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
const StudyAbroadDetail = () => {
    const {
        register,
        handleSubmit,
        reset,
      } = useForm();

    const {mutateAsync, isPending} = useEnquiry();

    const {universityslug} = useParams();
    const [showModal, setShowModal] = useState(false);
    const universityDetail = async () => {
    const newurl =
        process.env.REACT_APP_BASE_URL + `university/${universityslug}`;
        const response = await axios.get(newurl);
        return response?.data?.data;
    };
    
    const { data } = useQuery({
        queryKey: ["universityDetail"],
        queryFn: () => universityDetail(),
        enabled : Boolean(universityslug)
    });
      
    const ranking =  data?.ranking?.split("/");
    const onsubmit = (data) => {
        let newdata = {...data, mailtype : "contact us"}
        mutateAsync(newdata).then((resp) => {
            if(resp?.status===true){
            toast.success("Mail send sucessfully!");
            setShowModal(false);
            reset();
            }
        })
    }

    return (
        <> 
        <Helmet>
            <meta charSet="utf-8" />
            {data ? <title>University Detail {data?.title}</title>:""}
            <link rel="canonical" href={`https://saheducation.co.uk/university-detail/${data?.slug}`}/>
        </Helmet>
        <div className="study_abroad_section_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <div className="panel_intro">
                            <div class="padding-20 mb-m-10" style={{ border: "1px solid #dfdfdf" }}>
                                <div class="row unlogo">
                                    <img src={data?.bgprofile_logo} alt="" />
                                    <div className="sub_logo">
                                        <img src={data?.logo_image} alt=''/>
                                    </div>
                                </div>
                                <div class="text-center p-2">
                                    <div class="text-blue font-size18 font-bold text-center">{data?.title}</div><br />
                                    <div class="font-size13">
                                       {data?.location}
                                    </div>
                                </div>

                                <div class="row justify-content-center text-center p-2" >
                                    <div class="text-blue">
                                        <h5 class="text-black font-bold font-size14">Ranking</h5>
                                        {
                                            ranking?.map((value) => {
                                                return  <h5 class="text-black font-size13">{value}</h5>
                                            })
                                        }
                                    </div>
                                </div>

                                <div class="row justify-content-center text-center p-2">
                                    <div class="text-blue">
                                        <h5 class="text-black font-bold font-size14">Web Url</h5>
                                        <h5 class="text-black font-size13"><a class="text-blue" rel="noopener noreferrer" target="_blank" href="http://www.oregonstate.edu">{data?.weburl}</a></h5>
                                    </div>
                                </div>
                                <div class="row justify-content-center text-center p-2">
                                    <div class="text-blue">
                                        <h5 class="text-black font-bold font-size14">Remarks</h5>
                                        <h5 class="text-black font-size13">
                                           {data?.remarks}
                                        </h5>

                                    </div>
                                </div>
                                <button className="modal_btn" onClick={() => setShowModal(true)}>
                                    Talk to an Expert for Free
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-sm-12">
                        <div className="panel_section">
                            <img src={data?.feature_image} alt="" style={{ width: "100%", height: "400px" }} />

                            <div className='row tableuniversitydetail'>
                                <div dangerouslySetInnerHTML={{__html: data?.overview}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Contact Us</h2>
                        <div
                            className='close_btn'
                            onClick={() => setShowModal(false)}
                        >
                            X
                        </div>
                        <form onSubmit={handleSubmit(onsubmit)}>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    name="name"
                                    {...register('name', {required:true})}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    {...register('phone', {required:true})}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    {...register('email', {required:true})}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message:</label>
                                <textarea
                                    name="message"
                                    {...register('message')}
                                />
                            </div>
                            <div className="form-buttons">
                                <button type="submit" disabled={isPending ? true : false} className="submit-btn">{isPending ? 'Loading...' : "Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default StudyAbroadDetail