
import "./assets/style/global.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./layout/Navbar";
import Home from "./pages/Home";
import Footer from "./layout/Footer";
import ResponsiveNavbar from "./layout/ResponsiveNavbar";
import StudyAbroad from "./pages/StudyAbroad";
import About from "./pages/About";
import Contact from "./pages/Contact";
import StudyAbroadDetail from "./pages/StudyAbroadDetail";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Career from "./pages/Career";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const queryClient = new QueryClient();


const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Navbar />
        <ToastContainer />
        <ResponsiveNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/study-abroad/:countrycode" element={<StudyAbroad />} />
          <Route path="/university-detail/:universityslug" element={<StudyAbroadDetail />} />
          <Route path="/study-abroad/:id" element={<StudyAbroad />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
