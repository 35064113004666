import React from 'react'
import "../assets/style/career.css"
import { Link } from 'react-router-dom'
import Helping from "../assets/images/career/Helping.webp"
import Leading from "../assets/images/career/Leading.webp"
import technology from "../assets/images/career/technology.webp"
import c1 from "../assets/images/career/c1.webp"
import c2 from "../assets/images/career/c2.webp"
import c3 from "../assets/images/career/c3.webp"
import c4 from "../assets/images/career/c4.webp"

const Career = () => {
    return (
        <>
            <div className="career_web_banner">

                <div className='career_web_overlay'></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-12">
                            <div className="career-content">
                                <h1>Join Our Team</h1>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus, placeat. Dolores distinctio saepe accusantium similique possimus voluptatibus atque nisi reprehenderit.</p>
                                {/* <button className="know_more" style={{ width: "40%", color: "#fff" }}><Link>Apply Today</Link></button> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="career_section pb-80">
                <div className="container">
                    <h1>Our Team</h1>


                    <div className="featured_service_area">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <div className="card-blk">
                                    <h4>Career Guidance and Counselling</h4>
                                    <img src={Helping} alt="" />
                                    <p>At SAH Education, we provide expert career guidance and counseling to help students and professionals navigate their career paths. Our personalized approach ensures that individuals gain clarity on their goals, understand industry trends, and make informed decisions to achieve long-term success in their chosen fields.</p>

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="card-blk">
                                    <h4>College and University Selection</h4>
                                    <img src={Leading} alt="" />
                                    <p>At SAH Education, we assist students in selecting the right college or university that aligns with their academic and career aspirations. Through detailed research and expert advice, we guide students in making informed choices, ensuring they secure admissions in institutions that offer the best programs suited to their goals.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="card-blk">
                                    <h4>Financial Aid and Scholarship Guidance</h4>
                                    <img src={technology} alt="" />
                                    <p>SAH Education offers comprehensive guidance on financial aid and scholarships to help students fund their education. We assist in identifying scholarship opportunities, navigating application processes, and understanding financial aid options, ensuring that students have access to the resources they need to pursue their academic goals without financial barriers.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="career_section pb-80">
                <div className="container">
                    <h1>Job categories</h1>

                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <div className="carr_team_card">
                                <img src={c1} alt="" style={{ width: "100%" }} />
                                <h3>Education Counselors</h3>
                                <p>Guiding students on academic and career paths.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-12">
                            <div className="carr_team_card">
                                <img src={c2} alt="" style={{ width: "100%" }} />
                                <h3>Marketing and Outreach Specialists</h3>
                                <p> Promoting educational services and engaging with potential clients.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="carr_team_card">
                                <img src={c3} alt="" style={{ width: "100%" }} />
                                <h3>Administrative and Support Staff</h3>
                                <p>Managing office tasks, documentation, and student inquiries.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="carr_team_card">
                                <img src={c4} alt="" style={{ width: "100%" }} />
                                <h3>Student Advisors</h3>
                                <p>Offering personalized guidance on college selection, financial aid, and scholarships.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="career_contact_sec">
                <div className="container">
                    <h1>Apply Now</h1>

                    <div className="row">

                        <div className="col-lg-6 col-sm-12">
                            <div className="carrer_loc_map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19395.735134942242!2d-0.25554339365508827!3d52.579251447915965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877f182585fb8d5%3A0x592eeab1e708c676!2sSah%20Education%20Consultant%20And%20Recruiting%20Limited!5e0!3m2!1sen!2sus!4v1725108819434!5m2!1sen!2sus" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>


                        <div className="col-lg-6 col-sm-12">
                            <div className="career_con_box">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" id="name" name="name" placeholder="Enter your name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" placeholder="Enter your email" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Mobile Number</label>
                                        <input type="number" id="number" name="number" placeholder="Enter your number" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea name="" id="message" rows="5" column=""></textarea>
                                    </div>
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div >

        </>
    )
}

export default Career