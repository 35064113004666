import React, { useState } from 'react'
import "../assets/style/responsivenavbar.css";
import sahEducationLogo from "../assets/images/sahEducationLogo.webp";
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';

const ResponsiveNavbar = () => {
    

    const [resposiveMenu, setResponsiveMenu] = useState(false);
    const [servicesMenu, setServicesMenu] = useState(null);

    return (
        <div className="responsive_navbar">
            <div className="container">
                <nav className="responsive_navbar_section">
                    <div className="navbar_left">
                        <div className="logo_area">
                            <img src={sahEducationLogo} alt="" />
                        </div>
                    </div>
                    <div className="navbar_right">
                        <GiHamburgerMenu
                            fontSize="20px"
                            onClick={() => setResponsiveMenu(!resposiveMenu)}
                        />
                    </div>
                </nav>

                {resposiveMenu && (
                    <div className="responsive_menu_wrapper animate__animated animate__fadeInDown">
                        <div className="close_btn">
                            <IoMdCloseCircleOutline
                                fontSize="30px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                            />
                        </div>

                        <div className="resp_menu_list">
                            <ul className="resp_menu_items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About US</Link>
                                </li>
                                <li
                                    onClick={() =>
                                        setServicesMenu(
                                            servicesMenu === "services" ? null : "services"
                                        )
                                    }
                                >
                                    <Link>Study Abroad</Link>
                                    {servicesMenu === "services" ? <FiMinus /> : <GoPlus />}
                                </li>

                                {servicesMenu === "services" && (
                                    <ul className="responsive_submenu">
                                        <li>
                                            <Link to='/study-abroad/uk'>Study in UK</Link>
                                        </li>
                                        <li>
                                            <Link to='/study-abroad/australia'>Study in Australia</Link>
                                        </li>
                                        <li>
                                            <Link to='/study-abroad/canada'>Study in Canada</Link>
                                        </li>
                                        <li>
                                            <Link to='/study-abroad/usa'>Study in USA</Link>
                                        </li>
                                    </ul>
                                )}

                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ResponsiveNavbar